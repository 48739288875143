<template>
  <div class="app common-content">
    <Banner :BannerImg="BannerImg"></Banner>
    <div class="app-content">
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark flex">
            <span class="name">开通账号：</span>
            <div>
              <img src="../assets/images/ceo-pic.png" alt>
            </div>
            <span>爱哭的西瓜</span>
          </div>
        </el-col>
      </el-row>
      <!-- vip种类 -->
      <el-row :gutter="10" class="vip-type" v-if="payShow">
        <el-col
          :xs="7"
          :sm="7"
          :md="6"
          :lg="7"
          :xl="7"
          v-for="(item,index) in vips"
          :key="index"
          @click.native="checkVip(item.id, item.price)"
        >
          <span class="vip-type-money">{{item.price}}元</span>
          <span>/{{item.valid_time_desc}}</span>
          <svg class="icon icon-recommend" aria-hidden="true" v-if="index===1">
            <use xlink:href="#icon-ziyuan21"></use>
          </svg>

          <svg class="icon active-check" aria-hidden="true" v-if="item.id===checkVipId">
            <use xlink:href="#icon-ziyuan22"></use>
          </svg>
        </el-col>
      </el-row>
      <!-- 支付方式 -->
      <el-row :gutter="10" class="vip-pay">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="payShow">
          <div class="grid-content bg-purple-one">应付金额：{{payMoney}}元</div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">

            <div class="flex flexcolumn">
              <div class="vip-pay-icon weixin" @click='paySubscribe("wxpay_official")'  v-if="!payShow" >
                 <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-weixin"></use>
                </svg>
              </div>
              <div class="vip-pay-icon weixin" @click='payVip(checkVipId,"wxpay_official")' v-if="payShow">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-weixin"></use>
                </svg>
              </div>
              <p>微信</p>
            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="flex flexcolumn">
              <div class="vip-pay-icon zhifubao" @click='paySubscribe("wxpay_official")'  v-if="!payShow">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-icon-alipay"></use>
                </svg>
              </div>
              <div class="vip-pay-icon zhifubao" @click='payVip(checkVipId,"wxpay_official")' v-if="payShow">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-icon-alipay"></use>
                </svg>
              </div>
              <p>支付宝</p>
            </div>
          </el-col>
        </el-col>

        <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="!payShow"> -->
        <div class="pay-code">
          <div id="qrcode"></div>
        </div>
        <!-- </el-col> -->
      </el-row>
    </div>
  </div>
</template>
<script>
import Banner from '../components/Banner.vue'
import BannerImg from '../assets/images/vip-bg.jpg'
import QRcode from 'qrcodejs2'
export default {
  components: {
    Banner
  },
  data () {
    return {
      BannerImg: BannerImg,
      // vips列表
      vips: [],
      // 当前选中的vip的id
      checkVipId: 2,
      // 当前选中的应付金额
      payMoney: '',
      // 定时器
      time: null,
      payShow: true
    }
  },
  computed: {
    // 获取文章id & 订单id
    params () {
      return this.$store.state.storageId
    }
  },
  methods: {
    // 生成二维码  公用
    qrcode (id, url) {
      let qrcode = new QRcode(id, {
        width: 200,
        height: 200
      })
      qrcode.makeCode(url)
    },
    // 获取vip种类列表
    getVips () {
      this.$store.dispatch('getVips').then(res => {
        res.forEach(item => {
          if (item.id === this.checkVipId) {
            this.payMoney = item.price
          }
          this.vips.push(item)
        })
      })
    },
    // 选择vip
    checkVip (id, price) {
      if (document.getElementById('qrcode')) {
        document.getElementById('qrcode').innerHTML = ''
      }

      if (this.checkVipId !== id) {
        this.checkVipId = id
        this.payMoney = price
      }
    },
    // 提交付费支付方式
    paySubscribe (val) {
      console.log(this.params.orderId)
      if (document.getElementById('qrcode')) {
        document.getElementById('qrcode').innerHTML = ''
      }
      let params = {
        order_no: this.params.orderId,
        pay_type: val
      }
      console.log(params)
      this.$store.dispatch('paySubscribe', params).then(res => {
        // console.log(res)
        if (res.code === 10000) {
          alert(res.message)
          document.getElementById('qrcode').innerHTML = ''
        } else if (res.code === 0) {
          this.qrcode('qrcode', res.data.code_url)
          // 定时刷新用户是否付款成功，成功则跳转改文章详情页
          clearInterval(this.time)
          this.time = setInterval(() => {
            this.$store.dispatch('payOff', params.order_no).then(res => {
              // console.log(res)
              if (res.paid === 1) {
                this.$router.push({
                  name: 'Librarydetail',
                  params: { id: this.params.articleId }
                })
                clearInterval(this.time)
              }
            })
          }, 10000)
        }
      })
    },
    // VIP付款方式
    payVip (id, val) {
      if (document.getElementById('qrcode')) {
        document.getElementById('qrcode').innerHTML = ''
      }

      let params = {
        id,
        pay_type: val
      }
      this.$store.dispatch('payVip', params).then(res => {
        // console.log(res);
        this.qrcode('qrcode', res.code_url)
        // 定时刷新用户是否付款成功，成功则跳转改文章详情页
        clearInterval(this.time)
        this.time = setInterval(() => {
          this.$store.dispatch('payOff', res.order_no).then(res => {
            console.log(res)
            //  console.log(this.$router)
            if (res.paid === 1) {
              this.$router.go(-1)
              // this.$router.push({
              //   name: "ArticleDetail",
              //   params: { id: this.$router.params.id }
              // });
              clearInterval(this.time)
            }
          })
        }, 10000)
      })
    }
  },
  mounted () {
    clearInterval(this.time)
    if (this.$route.name === 'Paygoods') {
      this.payShow = false
    }
    if (this.$route.name === 'Vip') {
      this.payShow = true
    }
    if (this.$route.name === 'Librarydetail') {
      this.payShow = true
    }
    this.getVips()
  }
}
</script>
<style lang="less" scoped>
.app {
  .app-content {
    padding: 2rem 9rem;
    .el-row {
      margin-bottom: 2rem;
      .bg-purple-dark {
        justify-content: flex-start;
        div {
          width: 2.5rem;
          height: 2.5rem;
          margin-left: 0.3rem;
          margin-right: 0.8rem;
          img {
            width: 100%;
          }
        }
      }
    }
    .vip-type {
      .el-col {
        border: 1px solid @global-gray;
        border-radius: 1rem;
        padding: 2.5rem 0;
        margin: 0 0.8rem;
        color: #d7a152;
        position: relative;
        overflow: hidden;
        .vip-type-money {
          font-weight: bold;
          font-size: 1.6rem;
          letter-spacing: 0.1rem;
        }
        .icon-recommend {
          width: 4rem;
          position: absolute;
          left: 0;
          top: -3rem;
        }
        .active {
          border: 1px solid #d7a152;
          box-shadow: 0 0 2px #d7a152;
        }

        .active-check {
          width: 3rem;

          position: absolute;
          bottom: -3.3rem;
          right: 0;
        }
      }
    }
    .vip-pay {
      margin-top: 4rem;
      padding: 5rem;
      border: 1px dashed @global-gray;
      .bg-purple-one {
        font-size: 1.5rem;
        color: #d7a152;
        margin-bottom: 4rem;
      }
      .vip-pay-icon {
        padding: 30px;
        width: 5rem;
        height: 5rem;
        cursor: pointer;
        .icon {
          width: 4rem;
          height: 5rem;
        }
      }
      .weixin {
        border: 1px solid #4ab615;
      }
      .zhifubao {
        border: 1px solid #00abee;
      }
      .pay-code {
        margin: 0 auto;
        display: inline-block;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .app {
    .app-content {
      padding: 0.8rem;
      .vip-type {
        .el-col {
          margin: 0 0.3rem;
        }
      }
      .vip-pay {
        padding: 3rem 0;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .vip-type {
    .vip-type-money {
      font-size: 1rem !important;
    }
    span {
      font-size: 1rem;
    }
    .icon-recommend {
      width: 3rem !important;
      top: -3.3rem !important;
    }
  }
}
</style>
